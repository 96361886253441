<template>
    <v-container v-if="user">
        <v-row>
            <h2>Menus</h2>
            <v-spacer></v-spacer>
                <v-btn id="btn_cancel" color="primary" outlined @click="cancel" class="margin-left-right-default">Cancel</v-btn>
                <v-btn id="btn_save" color="primary" class="margin-right-default" :disabled="errorGlobal" @click="validateConfiguration()!=true?null:save()" :loading="loading">Save</v-btn>
        </v-row>
        <v-form
                ref="formService"
                v-model="valid"
               >
        <v-row align="center" v-if="serviceTypes">
                <v-col cols="12" sm="9">
                <v-select
                    id="service"
                        v-model="service"
                        :items="serviceTypesModified"

                        :return-object=true
                        label="Service"
                        :rules="[v => !!v && v.length !== 0 || 'You must select one Type of Service!']"
                        required>
                  <template v-slot:selection="{ item }">
                    {{ item.displayName }}
                  </template>
                  <template v-slot:item="{ item }">
                    <v-row no-gutters class="margin-default">
                      <div v-if= "item.name==='CLASSIC'" class="margin-default"><v-img src="../../assets/course_250.webp" height="200px" max-width="200px"/></div>
                      <div v-if = "item.name==='GRAZING'" class="margin-default"><v-img src="../../assets/grazing_250.webp" height="200px" max-width="200px"/></div>
                      <div v-if = "item.name==='FUNCTION' " class="margin-default"><v-img  src="../../assets/cocktail_250.webp" height="200px" max-width="200px"/></div>
                      <div v-if = "item.name==='SHARING'" class="margin-default"><v-img  src="../../assets/sharing_250.webp" height="200px" max-width="200px"/></div>
                      <div v-if = "item.name==='FOOD_TRUCK'" class="margin-default"><v-img  src="../../assets/foodtruck_250.webp" height="200px" max-width="200px"/></div>
                      <div v-if = "item.name==='CLASS'" class="margin-default"><v-img height="200px" width="200px"/></div>
                      <h2 style="align-self: center" class="margin-default" >{{ item.displayName }}</h2>
                    </v-row>
                  </template>
                </v-select>
                </v-col>
                <v-col cols="12" sm="3" align="center">
                    <v-btn id="btn_service_add" outlined small :disabled="!valid" @click="addService()" >Add Service</v-btn>
                </v-col>
        </v-row>
        </v-form>
        <popup-dish v-if="dishDialog" :dish-dialog="dishDialog" :new-dish="this.newDish" :service="this.userLocal.service[this.tab]" :item-category="this.newCategory" :close-action="closeDialogDish" :edit-action="addOrEditDish"></popup-dish>
        <popup-category v-if="categoryDialog" :category-dialog="categoryDialog" :new-category="this.newCategory" :service="this.userLocal.service[this.tab]" :close-action="closeDialogCategory" :edit-action="addOrEditCategory"></popup-category>
        <popup-package v-if="packageDialog" :package-dialog="packageDialog" :new-package="this.newPackage" :service="this.userLocal.service[this.tab]" :close-action="closeDialogPackage" :edit-action="addOrEditPackage"></popup-package>
        <v-tabs v-if="userLocal.service && userLocal.service.length!==0"
                fixed-tabs
                v-model="tab"
                background-color="primary"
                show-arrows
                >
            <v-tab v-for="(item,index) in userLocal.service"
                   :key="item.serviceType.name"
            >
                <div v-if="item">
<!--                  <v-row v-if="item" no-gutters class="margin-default">-->
<!--                    <div v-if= "item.serviceType.name==='CLASSIC'" class="margin-default"><v-img src="../../assets/course_250.webp" height="50px" max-width="50px"/></div>-->
<!--                    <div v-if = "item.serviceType.name==='GRAZING'" class="margin-default"><v-img src="../../assets/grazing_250.webp" height="50px" max-width="50px"/></div>-->
<!--                    <div v-if = "item.serviceType.name==='FUNCTION' " class="margin-default"><v-img  src="../../assets/cocktail_250.webp" height="50px" max-width="50px"/></div>-->
<!--                    <div v-if = "item.serviceType.name==='SHARING'" class="margin-default"><v-img  src="../../assets/sharing_250.webp" height="50px" max-width="50px"/></div>-->
                    {{item.serviceType.displayName}}
<!--                  </v-row>-->
<!--                  {{item.serviceType.displayName}}-->

                </div>
                <v-icon v-if="error[index]" color="error" class="margin-left-right-default">mdi-alert-circle-outline</v-icon>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" v-if="userLocal.service && userLocal.service.length!==0">
            <v-tab-item
                    :eager="true"
                    v-for="(service,index) in userLocal.service"
                    :key="service.serviceType.name"
            >
                <!-- Menu-->
                <v-card v-if="service" style="margin: 1px" elevation="4">
                    <v-col align-self="center">
                        <v-row  style="align-items: center;text-align: center;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                            <v-col cols="auto" >
                                <v-switch
                                    id="swc_enable"
                                        hide-details
                                        class="caption no-margin-padding "
                                        v-model="service.enabled"
                                        :value="service.enabled"
                                        :label="`Visible to public: ${service.enabled===true?'Visible':'Hidden'}`"
                                        @click="checkVisibility(service,index)"
                                ></v-switch>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" style="text-align: right">
                                <v-row style="align-items: center;">
                                    <v-switch
                                        id="swc_tags"
                                            hide-details
                                            class="caption no-margin-padding "
                                            v-model="showTags"
                                            label="show Tags"
                                    ></v-switch>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn id="btn_service_delete" icon color="primary" @click="deleteService(service)" v-bind="attrs"
                                                   v-on="on" >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </template>
                                        <span>Remove the service : {{service.serviceType.displayName}}</span>
                                    </v-tooltip>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row  style="text-align: center;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">

                            <v-col cols="12" style="align-self: center;">
                                <div>{{service.serviceType.displayName}}
                                  <br/>
                                  A la carte
                                </div>

                            </v-col>

                        </v-row>
                        <div class="align-center justify-center caption error--text" style="text-align: center;margin: -12px -12px 0px -12px;background-color: #f7f3f3;" v-if="checkMenuComputed">A menu needs at least a Starter and a Main or a Main and a Desert</div>
<!--                        <div v-for="(dishType) in $store.state.dishType" :key="dishType.name">-->
                        <div v-for="(category) in service.itemCategories" :key="category.name" class="margin-top-default">
<!--                            <div v-if="filteredDishesByCategory(service.dishes,category).length>0">-->

                              <div >
                                <div class="" style="text-align: center;">
                                    <v-row class="">
                                        <v-col align="start">
                                            <div style="display:inline;text-align: left;font-weight:bold ;"
                                                 class="primary--text">{{category.name}}
                                            </div>
                                        </v-col>
                                        <v-col align="end">
                                            $ per person
                                        </v-col>
                                      <v-col class="" cols="2" style="align-self: center; text-align: right;padding-bottom: 0px;">
                                        <v-btn icon color="primary" @click.prevent="moveUpCategory(service,category)">
                                          <v-icon>mdi-arrow-up</v-icon>
                                        </v-btn>
                                        <v-btn icon color="primary" @click.prevent="moveDownCategory(service,category)">
                                          <v-icon>mdi-arrow-down</v-icon>
                                        </v-btn>
                                        <v-btn icon color="primary" @click.prevent="openDialogCategory(service,category)">
                                          <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                        <v-btn icon color="primary" @click="deleteCategory(service,category)" >
                                          <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                </div>
                                <v-row style="text-align: left;" v-for="(dish,index) in filteredDishesByCategory(service.dishes,category)" v-bind:key="index" class="margin-top-default">
<!--                                <v-row style="text-align: left;" v-for="(dish,index) in category.items" v-bind:key="index" class="margin-top-default">-->
                                    <v-col style="align-self: center;margin-left: 12px;">
                                        <v-row style="align-items: center;">
                                            <v-col style="padding-bottom: 0px;">
                                                {{dish.description}}
                                            </v-col>
                                            <v-col class="col-auto" style="align-self: center;padding-bottom: 0px;">
                                                {{dish.price}}
                                            </v-col>
                                            <v-col class="" cols="2" style="align-self: center; text-align: right;padding-bottom: 0px;">
                                                <v-btn icon color="primary" @click.prevent="openDialogDish(service,category,dish)">
                                                    <v-icon>mdi-pencil-outline</v-icon>
                                                </v-btn>
                                                <v-btn icon color="primary" @click="deleteDish(service, category, dish)" >
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="showTags">
                                            <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" class="text--lighten-3">
                                                <v-chip v-for="tag in dish.styleTags"
                                                        :key="tag.name"
                                                        x-small
                                                        text-color="black"
                                                        color="primary-opacity4"
                                                >{{tag.displayName}}</v-chip>
                                            </v-col>
                                            <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
<!--                                                class="chip-text black&#45;&#45;text opacity25"-->
<!--                                                style="background:unset;"-->
                                                <v-chip v-for="tag in dish.ingredientTags"
                                                        :key="tag.name"
                                                        x-small
                                                        color="primary-opacity3"
                                                >{{tag.displayName}}</v-chip>
                                            </v-col>
                                            <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
                                                <v-chip v-for="tag in dish.dietaryTags"
                                                        :key="tag.name"
                                                        x-small
                                                        color="primary-opacity2"
                                                >{{tag.displayName}}</v-chip>
                                            </v-col>
                                            <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
                                                <v-chip v-for="tag in dish.equipments"
                                                        :key="tag.name"
                                                        x-small
                                                        x
                                                        color=""
                                                >{{tag.displayName}}</v-chip>
                                            </v-col>
                                        </v-row>

<!--                                            <v-row style="align-self: center">-->
<!--                                                <v-col>-->
<!--                                                    {{dish.description}}-->
<!--                                                </v-col>-->
<!--                                            </v-row>-->
<!--                                            <v-row style="align-self: center" >-->
<!--                                                -->
<!--                                            </v-row>-->
                                    </v-col>

<!--                                    <v-col class="col-auto" style="align-self: center">-->
<!--                                        {{dish.price}}-->
<!--                                    </v-col>-->
<!--                                    <v-col class="" cols="2" style="align-self: center; text-align: right;">-->
<!--                                        <v-btn icon color="primary" @click="openDialogDish(item,dish)">-->
<!--                                            <v-icon>mdi-pencil-outline</v-icon>-->
<!--                                        </v-btn>-->
<!--                                        <v-btn icon color="primary" @click="deleteDish(item,dish)" >-->
<!--                                            <v-icon>mdi-delete-outline</v-icon>-->
<!--                                        </v-btn>-->
<!--                                    </v-col>-->
<!--                                    <v-row>test</v-row>-->
                                </v-row>

                                <v-row align-content="center" style="text-align: center;" class="margin-top-bottom margin-default">
                                  <v-btn id="btn_item" class="col margin-top-default"
                                         color="primary"
                                         dark
                                         outlined
                                         @click="openDialogDish(service,category)"
                                  >
                                    Add a Dish
                                    <v-icon right dark>mdi-plus-circle-outline</v-icon>
                                  </v-btn>
                                </v-row>

                                <v-row >
                                  <v-divider class="margin-top-bottom" ></v-divider>
                                </v-row>


                            </div>
                        </div >

                      <v-row align-content="center" style="text-align: center;" class="margin-top-default">
                        <v-btn id="btn_category" class="col margin-default"
                               color="primary"
                               dark
                               outlined
                               @click="openDialogCategory(service)"
                        >
                          Add a Category
                          <v-icon right dark>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </v-row>


                      <!-- Packages-->
                      <div v-if="false" >
                        <v-row >
                          <v-divider class="margin-top-bottom" ></v-divider>
                        </v-row>

                        <v-row v-if="service.dishes === undefined || service.dishes.length==0" align-content="center" class="margin-bottom">
                          <v-col class=" error--text " style="text-align: center;font-weight: bold">Add some Items before creating a package</v-col>
                        </v-row>
                        <v-row v-else-if="isDirtyItems()" align-content="center" class="margin-bottom">
                          <v-col class=" error--text " style="text-align: center;font-weight: bold">Save before creating/editing a package</v-col>
                        </v-row>

                        <div v-if="service.packages !== undefined && service.packages.length > 0"  style="text-align: center;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                          Packages

                          <v-card elevation="4" class="margin-top-default" v-for="(pack) in service.packages" :key="pack.id">
                            <v-card-title>{{pack.name}} - ${{pack.price}} pp {{formatPackageMinMaxGuests(pack)}}
                              <v-btn id="btn_package_modify" icon color="primary" @click.prevent="openDialogPackage(service,pack)" :disabled="isDirtyItems()">
                                <v-icon aria-label="Modify Package" aria-hidden="false">mdi-pencil-outline</v-icon>
                              </v-btn>
                              <v-btn id="btn_package_delete" icon color="primary" @click="deletePackage(pack,service)" :disabled="isDirtyItems()">
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                            </v-card-title>
                            <v-card-text style="text-align: left;">
                              <div v-for="(category) in packageCategoriesSorted(pack)" :key="category.name" class="margin-top-default">
                                <div v-if="filteredDishesByCategoryForPackages(service,category).length>0">
                                {{category.itemCategory.name}} - {{formatPackageCategorySelection(category)}}
                                  <ul v-for="(item,index) in filteredDishesByCategoryForPackages(service,category)" v-bind:key="index" class="margin-top-default">
                                    <li>{{item.description}}</li>
                                  </ul>
                              </div>
                              </div>
                            </v-card-text>
                          </v-card>
                        </div>

                        <v-row align-content="center" style="text-align: center;">
                          <v-btn id="btn_package" class="col margin-default"
                                 color="primary"
                                 outlined
                                 @click="openDialogPackage(service)"
                                 :disabled="isDirtyItems()"
                          >
                            Add a Package
                            <v-icon right dark>mdi-plus-circle-outline</v-icon>
                          </v-btn>
                        </v-row>
                      </div>

                    </v-col>
                </v-card>

                <!-- Service Configuration-->
                <v-form
                        ref="formConfiguration"
                        :v-model="error[index]"
                        lazy-validation
                >
                  <v-card v-if="service" class="margin-card-default " elevation="4" >
                    <v-card-text style="text-align: left;">
                      <v-row align="center" no-gutters  @click="expandConfigaration=!expandConfigaration">
                        <div style="display:inline">
                          <div style="display:inline" class="primary--text">Service configuration</div>
                          <div v-if="error[tab]" style="display:inline" class="margin-left-right-default"><v-icon color="error">mdi-alert-circle-outline</v-icon></div>
                        </div>

                        <v-spacer></v-spacer>
                        <v-btn icon color="primary" >
                          <v-icon>{{expandConfigaration?'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
                        </v-btn>
                      </v-row>
                      <div v-if="expandConfigaration">
                        <v-row align="center" >
                          <v-col cols="12" md="12">
                            <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                              <v-text-field
                                  id="minimum_amount"
                                  label="Minimum Earnings ($)"
                                  v-model="service.minPrice"
                                  type="number"
                                  :rules="[v => !!v && v.length !== 0 || 'You must enter a minimum earnings !']"
                                  hint="The client will have to spend your minimum earnings. Example: If a client book for 2 people with a total of 160$ and your mminimum earnings is $200 you will get $200 minimum"
                                  :persistent-hint="helpMinPrice"
                                  @update:error="validateConfiguration()"
                              >
                              </v-text-field>
                              <v-btn id="btn_help" icon color="primary" @click="helpMinPrice=!helpMinPrice" @hover="helpMinPrice=!helpMinPrice">
                                <v-icon>mdi-help-circle</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </v-row>
                        <div class="primary--text margin-top-default">A la carte configuration</div>
                        <v-row align="start" >
                          <v-col cols="12" md="6">
                            <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                              <v-text-field
                                  id="minimum_guest"
                                  label="Minimum Guest"
                                  v-model="service.minGuests"
                                  type="number"
                                  :messages="helpMinGuest ? ['Clients won\'t be able to book your service if they select less than your minimum guest requirement. We don\'t recommend setting this field,use the field minimum earnings instead '] : []"
                              >
                              </v-text-field>
                              <v-btn icon color="primary" @click="helpMinGuest=!helpMinGuest">
                                <v-icon>mdi-help-circle</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                              <v-text-field
                                  id="maximum_guest"
                                  label="Maximum Guest"
                                  v-model="service.maxGuests"
                                  type="number"
                                  :messages="helpMaxGuest ? ['Clients won\'t be able to book your service if they select more than your maximum guest requirement '] : []"
                              >
                              </v-text-field>
                              <v-btn icon color="primary" @click="helpMaxGuest=!helpMaxGuest">
                                <v-icon>mdi-help-circle</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row align="start" >

                          <v-col cols="12" md="6">
                            <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                              <v-text-field
                                  id="minimum_dishes"
                                  label="Minimum of dishes to hire a wait person"
                                  v-model="service.minWaiterDishes"
                                  type="number"
                                  :messages="helpMinWaiterDishes ? ['If the total number of dishes ordered (Number of people X Number of courses) reaches your limit, the wait person cost will be automatically added  '] : []"
                              >
                              </v-text-field>
                              <v-btn icon color="primary" @click="helpMinWaiterDishes=!helpMinWaiterDishes">
                                <v-icon>mdi-help-circle</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                              <v-text-field
                                  id="amount_waiter"
                                  label="Wait person cost"
                                  v-model="service.waiterPrice"
                                  type="number"
                                  :messages="helpWaiterPrice ? ['Cost for hiring a wait person, this cost will automatically be added to the client booking when the minimum of dishes is reached'] : []"
                              >
                              </v-text-field>
                              <v-btn icon color="primary" @click="helpWaiterPrice=!helpWaiterPrice">
                                <v-icon>mdi-help-circle</v-icon>
                              </v-btn>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                  </v-card>

                </v-form>
                <!-- EXAMPLE-->
                <menu-example :service="service"></menu-example>

              <br/>
              <a v-if="false" class="margin-default " target="_blank" href="/profile" @click.prevent="openProfile()">Preview your public profile</a>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
    import {} from 'vuex';
    import MenuExample from './MenuExample';
    import PopupDish from './PopupDish';
    import PopupCategory from './PopupCategory';
    import PopupPackage from './PopupPackage';
    export default {
        name: "Menus",
        components: {MenuExample,PopupDish, PopupCategory, PopupPackage},
        data() {
            return {
                errorGlobal:false,
                error:[],
                validTest:[],
                tab:undefined,
                service:undefined,
                userLocal:undefined,
                valid:true,
                validConfiguration:true,
                serviceTypesModified:undefined,
                serviceTypesAvailable:undefined,
                helpMinPrice:true,
                helpMinGuest:true,
                helpMaxGuest:true,
                helpMinWaiterDishes:true,
                helpWaiterPrice:true,
                expandConfigaration:true,
                expandExample:true,
                expandDish:true,
                dishDialog:false,
                categoryDialog:false,
                packageDialog:false,
                errorMissingDishes:false,
                showTags:true,
                inputFileRule: [
                    value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!',
                ],
                newDish:{},
                newCategory:{},
                newPackage:{},
                loading:false,
            };
        },
        methods: {
          openProfile(){
            let path = `/profile/` + this.user.profile.profileName;
            window.open(path, '_blank');
          },
          getCurrentService(){
            return this.service
          },
          checkVisibility(item){
            console.log(item.enabled);
            let result=true;
            let errorMessage;
            if (item.enabled===true){
              let nbPhotos=this.userLocal.photos.filter(photo => photo.serviceType.name === item.serviceType.name).length;
              // let service=this.userLocal.service.find(s => s.serviceType.name === item.serviceType.name);
              // console.log(nbPhotos)
              if (nbPhotos<3){
                // item["enabled"]=false;
                errorMessage="Please add at least 3 photos for this type of service: "+item.serviceType.displayName;
                this.showSnackbar({text: errorMessage , color: "error"});
                // service["enabled"]=false;
                result = false;
                // this.userLocal.service[index].enabled=false;
                // this.$forceUpdate();
                // item.enabled=false;
              }
            }
            console.log(result)
            return false;
          },
            closeDialogDish(){
                this.dishDialog=false;
            },
            openDialogDish(service, category, editItem){
                console.log(editItem);
                this.newDish=editItem;
                this.newCategory=category;
                this.dishDialog=true;
            },
            closeDialogCategory(){
              this.categoryDialog=false;
            },
            openDialogCategory(service,editItem){
              // console.log(editItem);
              this.newCategory=editItem;
              this.categoryDialog=true;
            },
          moveUpCategory(service,category){
            console.log(service,category);
            let index = service.itemCategories.findIndex(item => item === category);
            if(index > 0) {
              let temp = service.itemCategories[index].ordering;
              service.itemCategories[index].ordering = service.itemCategories[index - 1].ordering;
              service.itemCategories[index - 1].ordering = temp;

              service.itemCategories.sort(((a, b) => a.ordering - b.ordering));
              // Update ordering
              // service.itemCategories[index].ordering = index + 1;
              // service.itemCategories[index - 1].ordering = index;
              this.correctOrdering(service);
            }
          },
          moveDownCategory(service,category){
            console.log(service,category);
            let index = service.itemCategories.findIndex(item => item === category);
            if(index < service.itemCategories.length - 1) {
              let temp = service.itemCategories[index].ordering;
              service.itemCategories[index].ordering = service.itemCategories[index + 1].ordering;
              service.itemCategories[index + 1].ordering = temp;

              service.itemCategories.sort(((a, b) => a.ordering - b.ordering));
              // Update ordering
              // service.itemCategories[index].ordering = index + 1;
              // service.itemCategories[index + 1].ordering = index + 2;
              this.correctOrdering(service);
            }
          },
          correctOrdering(service) {
            let orderingSet = new Set();
            for(let i = 0; i < service.itemCategories.length; i++) {
              while(orderingSet.has(service.itemCategories[i].ordering)) {
                service.itemCategories[i].ordering++;
              }
              orderingSet.add(service.itemCategories[i].ordering);
            }

            // Sort the categories array by ordering
            service.itemCategories.sort((a, b) => a.ordering - b.ordering);
            console.log(service.itemCategories);
          },
            openDialogPackage(service,editPackage){
              // console.log(editPackage);
              this.newPackage=editPackage;
              this.packageDialog=true;
            },
            closeDialogPackage(){
              this.packageDialog=false;
            },
            deleteService(service){
                if ( confirm( "Are you sure you want to delete this service ?" ) ) {
                    this.userLocal.service = this.userLocal.service.filter(item => item !== service)
                    if (this.userLocal.service.length===0){
                        delete this.userLocal.service;
                    }
                  this.validateConfiguration();
                  // this.setServiceTypesModified(this.$store.state.serviceType);
                  this.serviceTypesModified = this.$store.state.serviceType;
                  // this.updateServiceTypesList(this.userLocal);
                  this.$forceUpdate();
                }
            },
            deleteDish(item,category, editItem){
                item.dishes = item.dishes.filter(item => item !== editItem)
              // category.items = category.items.filter(item => item !== editItem)
            },
            addOrEditDish(dish,itemCategory,service){
                // let service=this.userLocal.service[this.tab];
              let index=dish.index;
              delete dish.index;
              if (dish.dietaryTags.length === 0){
                delete dish.dietaryTags;
              }
              if (dish.ingredientTags.length === 0){
                delete dish.ingredientTags;
              }
              if (dish.styleTags.length === 0){
                delete dish.styleTags;
              }
              if (dish.equipments.length === 0){
                delete dish.equipments;
              }

                if (dish.edit){
                  delete dish.edit;
                  this.$set(service.dishes,[index],dish);
                  // this.$set(itemCategory.items,[index],dish);
                }else{
                  // if (itemCategory.items ===undefined){
                  //   itemCategory.items=[];
                  // }
                  if (service.dishes===undefined){
                    service.dishes=[];
                  }
                    service.dishes.push(JSON.parse(JSON.stringify(dish)));
                    // this.$refs.formDish.reset();

                  // itemCategory.items.push(JSON.parse(JSON.stringify(dish)));
                }
                service.dishes.sort(((a, b) => a.price - b.price));
                // this.$set(this.userLocal.service, this.tab, service);
                this.$set(this.userLocal.service, this.tab, service);
                this.$set(service, 'dishes', service.dishes);
                this.filteredDishesByCategory(service.dishes, itemCategory);
                this.closeDialogDish();
                this.$forceUpdate();
            },
          addOrEditCategory(category,service){
            console.log(category)
            // let service=this.userLocal.service[this.tab];
            let index=category.index;
            if (index!==undefined){
              let previousCategory = JSON.parse(JSON.stringify(service.itemCategories[index]));
              let dishes = this.filteredDishesByCategory(service.dishes, previousCategory);
              for (const dishesKey in dishes) {
                dishes[dishesKey].itemCategory = category;
              }
            }

            delete category.index;
            if (category.edit){
              delete category.edit;
              service.itemCategories[index] = JSON.parse(JSON.stringify(category));
              // this.$set(service.itemCategories,[index],JSON.parse(JSON.stringify(category)));
            }else{
              if (service.itemCategories===undefined){
                service.itemCategories=[];
              }
              service.itemCategories.push(JSON.parse(JSON.stringify(category)));

              // this.$refs.formDish.reset();
            }

            service.itemCategories.sort(((a, b) => a.ordering - b.ordering));
            // this.$set(this.userLocal.service, this.tab, service);
            this.closeDialogCategory();
            // this.$forceUpdate();
          },
          deletePackage(pack,service){

            service.packages = service.packages.filter(p => p !== pack)
          },
          addOrEditPackage(newPackage,service){
            let index=newPackage.index;
            delete newPackage.index;
            if (service.packages === undefined){
              service.packages = [];
            }
            newPackage.packageCategories.sort(((a, b) => a.itemCategory.ordering - b.itemCategory.ordering));
            if (index!==-1){
              console.log(index)
              if (newPackage.edit){
                delete newPackage.edit;
                service.packages[index] = JSON.parse(JSON.stringify(newPackage));
              }else{
                service.packages.push(newPackage);
              }
            }
            service
            console.log("addOrEditPackage");
            console.log(newPackage);
            console.log(service);
            this.closeDialogPackage();
          },
          deleteCategory(item,editItem){

            item.itemCategories = item.itemCategories.filter(item => item !== editItem)
            item.itemCategories.sort(((a, b) => a.ordering - b.ordering));
            let index=0;
            for (const cat in item.itemCategories) {
              if (item.itemCategories[cat].categoryType !== undefined  && item.itemCategories[cat].categoryType.name === "OTHER" ){
                item.itemCategories[cat].ordering=100 + index;
                index++;
              }
            }
            item.dishes = item.dishes.filter(dish => dish.itemCategory.name !== editItem.name);
          },
          addService(){
              this.validateService();
              //
              let service={serviceType:this.service,dishes:[]/*,newDish:{}*/};
              // this.$set(service, 'dishes', []);
              if (this.userLocal.service===undefined){
                  // this.$set(this.userLocal, 'service', []);
                  this.userLocal.service=[];
              }

            var index =this.userLocal.service.findIndex(item => item.serviceType.name === this.service.name);

            if (index > -1) {
              // this.error = error.errorMessage;
              this.showSnackbar({text: "The service "+this.service.displayName + " already exist." , color: "error"});
              //error serviceType already exist
            }else{
              this.userLocal.service.push(service);
            }

            // for (var i = 0; i < this.userLocal.service.length; i++) {
            //   // find the index of the item in serviceTypesModified
            //   var index =  this.serviceTypesModified.findIndex(item => JSON.stringify(item.name) === JSON.stringify(this.userLocal.service[i].serviceType.name));
            //   // if the item is found, remove it
            //   if (index > -1) {
            //     this.serviceTypesModified.splice(index, 1);
            //   }
            // }

              // this.userLocal.service.push(service);
              // let s=this.service;
              // this.serviceTypesModified=this.serviceTypesModified.filter(function (el) {
              //   return el.name !== s.name;
              // });
            this.tab=this.userLocal.service.length-1;
            this.$refs.formService.reset()
          },
          cancel(){
              let copy=JSON.parse(JSON.stringify(this.$parent.user));
              // if (copy.service===undefined){
              //     copy.service=[];
              // }else{
                  if (copy.service!==undefined) {
                      copy.service.forEach(service => {
                          if (service.dishes === undefined) {
                              service.dishes = [];
                          }
                        if (service.itemCategories === undefined) {
                          service.itemCategories = [];
                        }
                          /*service.newDish = {};*/
                      })
                  }
              // }

            this.userLocal=copy;
            console.log("cancel")
            console.log(this.userLocal);

          },
            updateServiceTypesList(userLocal){
              if (this.serviceTypesModified!==undefined){
                if (userLocal !=undefined && userLocal.service!==undefined){
                  for (var i = 0; i < userLocal.service.length; i++) {
                    // find the index of the item in serviceTypesModified
                    var index =  this.serviceTypesModified.findIndex(item => JSON.stringify(item.name) === JSON.stringify(userLocal.service[i].serviceType.name));
                    // if the item is found, remove it
                    if (index > -1) {
                      this.serviceTypesModified.splice(index, 1);
                    }
                  }
                }
              }
            },
            setServiceTypesModified(serviceTypes){
              this.serviceTypesModified=JSON.parse(JSON.stringify(serviceTypes));
              this.serviceTypesModified=this.serviceTypesModified.filter(function (el) {
                // Hiding CLass for now
                return el.name !== "CLASS"
              });
              console.log("create sevice list")
              this.updateServiceTypesList(this.userLocal)
            },
            save() {
              this.$parent.save(this);
            },
            validateService () {
                if (this.$refs.formService!==undefined){
                    this.$refs.formService.validate();
                }
            },
            validateConfiguration () {
                console.log("validate configuration");
                this.errorGlobal=false;
                let errors=[];
                if (this.userLocal.service!==undefined){
                    for (var i=0;i<this.userLocal.service.length;i++){
                        if (this.$refs.formConfiguration!=undefined && this.$refs.formConfiguration[i]!==undefined){
                            this.error[i]=!this.$refs.formConfiguration[i].validate();
                            if (this.error[i]==true){
                              errors.push("you have an error in " + this.userLocal.service[i].serviceType.displayName);
                                this.errorGlobal=true;
                            }
                        }
                    }
                }
                if (this.errorGlobal){
                  this.showSnackbar({text: errors.join("\n"), color: "error"});
                }

                return !this.errorGlobal;
            },
            filteredDishes(dishes, dishType){
                if (dishes !== undefined && dishType!==undefined){
                    return dishes.filter(d => d.dishType!==undefined && d.dishType.name === dishType.name)
                }else{
                    return [];
                }
            },
          filteredDishesByCategory(dishes, category){
            if (dishes !== undefined && category!==undefined){
              return dishes.filter(d => d.itemCategory!==undefined && d.itemCategory.name === category.name)
            }else{
              return [];
            }
          },
          getCategories(){
            if (this.tab!==undefined){
              console.log(this.userLocal.service[this.tab].itemCategories)
              return this.userLocal.service[this.tab].itemCategories;
            }
            return
          },
          isDirtyItems(){
            let local;
            if (this.userLocal.service!=undefined) {
              local = JSON.stringify(this.userLocal.service.map(s => s.dishes));
            }
            let remote;
            if ( this.$parent.user.service!=undefined) {
              remote = JSON.stringify(this.$parent.user.service.map(s => s.dishes));
            }
            if (local !== remote){
                return true;
            }
            return false;
          }
        },
        computed: {

            user() {
                if (this.$parent.user != undefined) {
                    // this.updateServiceTypesList(this.$parent.user);
                    this.cancel();
                }
                return this.$parent.user;
            },
            serviceTypes() {
                let types = this.$store.state.serviceType;
                if (types != undefined /*&& this.serviceTypesModified === undefined*/) {
                  console.log("serviceTypes")
                    this.setServiceTypesModified(types);
                }
                return types;
            },
            checkMenuComputed() {
                let error = false;
                let service = this.userLocal.service[this.tab];
                if (service !== undefined) {
                    // let mains = service.dishes.length > 0 ? service.dishes.filter(d => d.dishType.name === "MAIN").length : 0;
                    // let starters = service.dishes.length > 0 ? service.dishes.filter(d => d.dishType.name === "STARTER").length : 0;
                    // let deserts = service.dishes.length > 0 ? service.dishes.filter(d => d.dishType.name === "DESERT").length : 0;
                    // if ((starters === 0 && deserts === 0) || mains === 0) {
                    //     error = true;
                    // }
                }
                return error;
            },
        },
        beforeRouteLeave (to, from, next) {
            let copyLocal=JSON.parse(JSON.stringify(this.userLocal));

            if (copyLocal.service !=undefined && copyLocal.service.length===0){
                delete local.service;
            }else if (copyLocal.service !=undefined && copyLocal.service.length!==0){
                copyLocal.service.forEach(service => {
                    delete service.example;
                    delete service.newDish;
                    if (service.dishes.length===0){
                      delete service.dishes;
                    }
                })
            }
            let local=JSON.stringify(copyLocal);
            let remote=JSON.stringify(this.$parent.user);
            if (local !== remote){
                this.$parent.dirty=true;
                this.$parent.next=next;
            }else{
                next();
            }
        }
    }
</script>

<style scoped>

</style>