<template>
    <v-dialog v-if="checkDish" v-model="dishDialog" persistent max-width="1200px">
        <!--            <template >-->
        <!--                        v-slot:activator="{ on, attrs }"-->
        <!--                        <v-btn-->
        <!--                                color="primary"-->
        <!--                                dark-->
        <!--                                v-bind="attrs"-->
        <!--                                v-on="on"-->
        <!--                                @click="openDialogDish(item)"-->
        <!--                        >-->
        <!--                            Add a Dish-->
        <!--                        </v-btn>-->
        <!--            </template>-->
        <v-form
                ref="formDish"
                v-model="validDish"
                lazy-validation
                @submit.prevent="validateDish()!=true?false:edit()"
        >
            <v-card>
                <v-card-title>
                    <div class="headline">Dish</div>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row align="start" >
                            <v-col cols="12" md="12">
                                <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                                    <v-text-field
                                        id="dish_description"
                                            label="Dish description"
                                            v-model="localDish.description"
                                            type="text"
                                            :rules="[v => !!v && v.length !== 0 || 'You must enter a description !']"
                                            required
                                    >
                                    </v-text-field>

                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row align="start" >

<!--                            <v-col cols="12" md="6">-->
<!--                                <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">-->
<!--                                    <v-select-->
<!--                                        id="dish_type"-->
<!--                                            v-model="localDish.dishType"-->
<!--                                            :items="dishTypes"-->
<!--                                            item-text="displayName"-->
<!--                                            :return-object=true-->
<!--                                            label="Dish Type"-->
<!--                                            :rules="[v => !!v && v.length !== 0 || 'You must select one Type !']"-->
<!--                                            required-->
<!--                                    ></v-select>-->
<!--                                  <v-select-->
<!--                                      id="category"-->
<!--                                      v-model="localDish.itemCategory"-->
<!--                                      :items="categories"-->
<!--                                      item-text="name"-->
<!--                                      :return-object=true-->
<!--                                      label="Category"-->
<!--                                      :rules="[v => !!v && v.length !== 0 || 'You must select one Category !']"-->
<!--                                      required-->
<!--                                  ></v-select>-->
<!--                                </v-row>-->
<!--                            </v-col>-->
                            <v-col cols="12" md="6">
                                <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                                    <!--                                    && !$refs.price[tab].hasError-->
                                    <v-text-field
                                        id="dish_price"
                                            ref="price"
                                            label="Price per person"
                                            v-model.number="localDish.price"
                                            type="number"
                                            :rules="[v => !!v && v.length !== 0 || 'You must enter a price !']"
                                            hint="The price per person must include everything (Taxes, buying/preparation/driving/cooking/serving/cleaning time )"
                                            :persistent-hint="helpDishPrice"
                                    >

                                    </v-text-field>
                                    <v-btn icon color="primary" @click="helpDishPrice=!helpDishPrice">
                                        <v-icon>mdi-help-circle</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                                <v-select
                                    id="category"
                                    v-model="localDish.itemCategory"
                                    :items="categories"
                                    item-text="name"
                                    :return-object=true
                                    label="Category"
                                    :rules="[v => !!v && v.length !== 0 || 'You must select one Category !']"
                                    required
                                ></v-select>
                              </v-row>
                          </v-col>
                        </v-row>
                        <v-row align="start" >
                            <v-col cols="12" md="6">
                                <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                                    <v-col>
                                        <div>
                                            <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                                                <v-input class="input-chip" :persistent-hint=helpIngredients hint="This list of ingredients will be used to deduct the dietary tags! Fill in this section carefully. Animal Product can be : egg, milk, honey, ...">
                                                    <div slot="label" style="font-size: 12px" class="primary--text">This dish contains :</div>
                                                    <v-item-group multiple v-model="localDish.ingredientTags" @change="generateDietaryTags()">
                                                        <!--                                                :class="checkChip(localDish.dietaryTags,tag)? 'active':''"-->
                                                        <v-item
                                                                v-for="tag in ingredientTags"
                                                                :key="tag.name"
                                                                v-slot:default="{ active, toggle }"
                                                                :value="tag"
                                                                color="primary"
                                                        >
                                                            <v-chip
                                                                :id="tag.name"
                                                                    active-class="primary--text"
                                                                    :input-value="checkChip(localDish.ingredientTags,tag)"
                                                                    filter
                                                                    @click="toggle"
                                                            >
                                                                {{ tag.displayName }}
                                                            </v-chip>
                                                        </v-item>
                                                    </v-item-group>
                                                </v-input>
                                                <v-btn icon color="primary" @click="helpIngredients=!helpIngredients">
                                                    <v-icon>mdi-help-circle</v-icon>
                                                </v-btn>
                                            </v-row>
                                            <div v-if="localDish.dietaryTags" class="padding-top-bottom-default">
                                                <div style="font-size: 12px;" class=" primary--text">Generated Dietary Tags</div>
                                                <v-chip v-for="tag in localDish.dietaryTags"
                                                        :key="tag.name"
                                                        x-small
                                                        color="primary "
                                                        :id="tag.name"
                                                >{{tag.displayName}}</v-chip>
                                            </div>
                                        </div>

                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                                    <v-col>
                                        <div style="font-size: 12px" class="primary--text">Style Tags</div>
                                        <v-item-group multiple v-model="localDish.styleTags">
                                            <v-item
                                                    v-for="tag in styleTags"
                                                    :key="tag.name"
                                                    v-slot:default="{ active, toggle }"
                                                    :value="tag"
                                                    color="primary"
                                            >
                                                <v-chip
                                                    :id="tag.name"
                                                        active-class="primary--text"
                                                        :input-value="checkChip(localDish.styleTags,tag)"
                                                        filter
                                                        @click="toggle"
                                                >
                                                    {{ tag.displayName }}
                                                </v-chip>
                                            </v-item>
                                        </v-item-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row align="start" >
                            <v-col cols="12" md="6">
                                <v-row no-gutters align="center" align-content="center" style="flex-wrap: nowrap;">
                                    <v-col>
                                        <div style="font-size: 12px" class="primary--text">Equipment Tags</div>
                                        <v-item-group multiple v-model="localDish.equipments">
                                            <v-item
                                                    v-for="tag in equipments"
                                                    :key="tag.name"
                                                    v-slot:default="{ active, toggle }"
                                                    :value="tag"
                                                    color="primary"
                                            >
                                                <v-chip
                                                    :id="tag.name"
                                                        active-class="primary--text"
                                                        :input-value="checkChip(localDish.equipments,tag)"
                                                        filter
                                                        @click="toggle"
                                                >
                                                    {{ tag.displayName }}
                                                </v-chip>
                                            </v-item>
                                        </v-item-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <!--                                            <v-row align="end" justify="end" no-gutters >-->
                        <!--                                                <v-btn type="submit" outlined color="primary" class="margin-left-right-default">Add the Dish</v-btn>-->
                        <!--                                            </v-row>-->
                        <!--                                        </div>-->
                        <!--                                    </v-card>-->

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="btn_dish_close" outlined @click="closeAction()">Close</v-btn>
                    <v-btn id="btn_dish_action" type="submit" color="primary" :disabled="!validDish">{{localDish.edit===true?"Edit":"Add"}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    export default {
        name: "PopupDish",
        props: {
            service:Object,
            itemCategory:Object,
            dishDialog:Boolean,
            newDish: Object,
            closeAction: Function,
            editAction:Function,
        },
        data() {
            return {
                localDish:{},
                expandExample:true,
                example:undefined,
                dishTypesSelected:[],
                validDish:true,
                helpDishPrice:true,
                helpIngredients:true,
            };
        },
        methods:{
            edit(){
              // this.newDish=this.localDish;
              this.editAction(this.localDish, this.itemCategory, this.service );
            },
            updateLocalDish(){
                    let copy;
                    let service=this.service;
                    let category=this.itemCategory;
                    if(this.newDish!==undefined) {
                        copy = JSON.parse(JSON.stringify(this.newDish));
                        // this.newDish=JSON.parse(JSON.stringify(editItem));;
                        copy.edit = true;
                        copy.index = service.dishes.indexOf(this.newDish);
                      //   copy.index = category.items.indexOf(this.newDish);
                        // copy.itemCategory=category; necessary if we allow item to move category
                    }else{
                        copy={};
                        copy.description=undefined;
                        copy.price=undefined;
                        copy.dishType=undefined;
                        copy.itemCategory=category;
                    }

                        let dietaryTags=[];
                        if (copy.dietaryTags!==undefined){
                            this.dietaryTags.forEach(dt => {
                                copy.dietaryTags.forEach(nddt => {
                                    if (nddt.name===dt.name){
                                        dietaryTags.push(dt);
                                    }
                                })
                            })
                        }
                        copy.dietaryTags=dietaryTags;
                        let ingredientTags=[];
                        if (copy.ingredientTags!==undefined){
                            this.ingredientTags.forEach(dt => {
                                copy.ingredientTags.forEach(nddt => {
                                    if (nddt.name===dt.name){
                                        ingredientTags.push(dt);
                                    }
                                })
                            })
                        }
                        copy.ingredientTags=ingredientTags;
                        let styleTags=[];
                        if (copy.styleTags!==undefined){
                            this.styleTags.forEach(dt => {
                                copy.styleTags.forEach(nddt => {
                                    if (nddt.name===dt.name){
                                        styleTags.push(dt);
                                    }
                                })
                            })
                        }
                        copy.styleTags=styleTags;
                        let equipments=[];
                        if (copy.equipments!==undefined){
                            this.equipments.forEach(dt => {
                                copy.equipments.forEach(nddt => {
                                    if (nddt.name===dt.name){
                                        equipments.push(dt);
                                    }
                                })
                            })
                        }
                        copy.equipments=equipments;

                    // this.newDish=copy;
                    this.generateDietaryTags();
                this.localDish=copy;
                return this.localDish;
            },
            generateDietaryTags(){
                var dietaryTags=[];
                if (this.localDish.ingredientTags!==undefined){
                    if (this.localDish.ingredientTags.filter(value => value.name==="DAIRY").length===0){
                        // Dairy Free
                        dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="DAIRY_FREE"));
                    }
                    if (this.localDish.ingredientTags.filter(value => value.name==="NUTS").length===0){
                        // NUTS Free
                        dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="NUT_FREE"));
                    }
                    if (this.localDish.ingredientTags.filter(value => value.name==="GLUTEN").length===0){
                        // Gluten Free
                        dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="GLUTEN_FREE"));
                    }
                    if (this.localDish.ingredientTags.filter(value => value.name==="MEAT" || value.name==="FISH" || value.name==="SHELLFISH").length===0){
                        // Vegetarian
                        dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="VEGETARIAN"));
                    }
                    if (this.localDish.ingredientTags.filter(value => value.name==="MEAT" || value.name==="FISH" || value.name==="SHELLFISH" || value.name==="ANIMAL" || value.name==="DAIRY").length===0){
                        // Vegan
                        dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="VEGAN"));
                    }
                    if (this.localDish.ingredientTags.filter(value => value.name==="MEAT" ).length===0){
                        // Pescatarian
                        dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="PESCATARIAN"));
                    }
                }else{
                    dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="DAIRY_FREE"));
                    dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="NUT_FREE"));
                    dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="GLUTEN_FREE"));
                    dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="VEGETARIAN"));
                    dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="PESCATARIAN"));
                    dietaryTags.push(this.$store.state.dietaryTag.find(d => d.name==="VEGAN"));
                }
                this.localDish.dietaryTags=dietaryTags;
                // this.$set(this.newDish,'dietaryTags',dietaryTags);
                // this.$forceUpdate();
                return dietaryTags;
            },
            checkChip(tags,tag) {
                let found = false;
                if (tags!==undefined){
                    found=tags.filter(t => t.name === tag.name).length > 0 ? true : false;
                }else{
                    found=false
                }
                // return tags !== undefined && tags.filter(t => t.name === tag.name).length > 0 ? true : false;
                return found;
            },
            addOrRemoveChip(tags,tag) {
                if ( this.checkChip(tags,tag) ){
                    tags = tags.filter(t => t.name !== tag.name);
                    return true;
                }else{
                    if (tags===undefined){
                        tags=[];
                    }
                    tags.push(tag);
                    return false;
                }
            },
            validateDish () {
                if (this.$refs.formDish!==undefined && this.$refs.formDish!==undefined){
                    return this.$refs.formDish.validate();
                }
                return true;
            },
        },
        computed:{
            dishTypes() {
                return this.$store.state.dishType;
            },
            categories(){
                return this.service.itemCategories;
            },
            dietaryTags() {
                return this.$store.state.dietaryTag;
            },
            ingredientTags() {
                return this.$store.state.ingredientTag;
            },
            styleTags() {
                return this.$store.state.styleTag;
            },
            equipments() {
                return this.$store.state.equipment;
            },
            checkDish(){
                let newDish=this.newDish;
                return this.updateLocalDish(newDish);
            }
        },
    }
</script>

<style scoped>

</style>