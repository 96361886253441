<template>
    <v-container v-if="user">
        <v-form
                ref="form"
                v-model="valid"
                lazy-validation
        >
        <v-row class="margin-bottom">
            <h2>Details</h2>
            <v-spacer></v-spacer>
                <v-btn id="btn_cancel" outlined @click="cancel" class="margin-left-right-default">Cancel</v-btn>
                <v-btn id="btn_save" color="primary" class="margin-right-default" :disabled="!valid"  @click="save()" :loading="loading">Save</v-btn>
        </v-row>

        <v-text-field
                id="firstname"
                label="Firstname"
                v-model="userLocal.userDetails.firstname"
                :rules="[v => !!v  || 'This field is required!']"
                required
        >
        </v-text-field>
        <v-text-field
            id="lasttname"
                label="Lastname"
                v-model="userLocal.userDetails.lastname"
                :rules="[v => !!v || 'This field is required!']"
        >
        </v-text-field>
        <v-menu
                ref="datepicker"
                v-model="datepicker"
                :close-on-content-click="false"
                max-width="290"
                offset-yc
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    id="dob"
                        clearable
                        readonly
                        label="Date Of Birth"
                        hint="yyyy/mm/dd"
                        v-on="on"
                        :value="userLocal.userDetails.dob"
                        @click:append="datepicker = true"
                        @click:prepend="datepicker = true"
                        @click:clear="userLocal.userDetails.dob = null"
                        :rules="[v => !!v || 'This field is required!']"
                ></v-text-field>
            </template>
            <v-date-picker
                    ref="picker"
                    v-model="userLocal.userDetails.dob"
                    :max="new Date().toISOString().substr(0, 10)"
                    @change="saveDate"
                    @input="datepicker = false"
                    color="primary"
                    width="290"
            ></v-date-picker>
        </v-menu>
        <v-autocomplete
            id="address"
            :no-filter="true"
                clearable
                v-model="address"
                :items="addressItems"
                :loading="isLoading"
                :search-input.sync="lookupAddress"
                item-text="place_name"
                label="Location"
                placeholder="Start typing your address"
                prepend-icon=""
                append-icon=""
                return-object
                @change="getLocation"
                auto-select-first
        ></v-autocomplete>
        <v-text-field
                disabled
                label="Address"
                v-model="userLocal.userDetails.address"
        >
        </v-text-field>
        <v-text-field
                disabled
                label="City"
                v-model="userLocal.userDetails.city"
        >
        </v-text-field>
        <v-text-field
                disabled
                label="Post Code"
                v-model="userLocal.userDetails.postcode"
        >
        </v-text-field>
          <v-text-field
              disabled
              label="Region"
              v-model="userLocal.userDetails.region"
          >
          </v-text-field>
        <v-text-field
                disabled
                label="Country"
                v-model="userLocal.userDetails.country"
        >
        </v-text-field>
        <v-text-field
                label="Phone Number"
                v-model="userLocal.userDetails.phone"
        >
        </v-text-field>

        </v-form>
    </v-container>

</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        name: "Details",
        data() {
            return {
                valid:true,
                userLocal:undefined,
                datepicker:false,
                loading:false,
                address:undefined,
                fullAddress:undefined,
                addressItems:undefined,
                isLoading:false,
                lookupAddress:undefined,
            };
        },
        methods: {
            cancel(){
                let copy=JSON.parse(JSON.stringify(this.$parent.user));
                // let copy=JSON.parse(JSON.stringify(this.$store.state.user));

                this.$set(copy, 'userDetails',copy.userDetails);

                if (copy.userDetails===undefined){
                    copy.userDetails={};
                }
                if (copy.userDetails.fullAddress!==undefined){
                  this.address={place_name: copy.userDetails.fullAddress };
                  this.addressItems = [{place_name: copy.userDetails.fullAddress }];
                }
                this.userLocal=copy;
            },
            async save() {
                if (this.validate()!=true){
                    return
                }
                if (this.address!=undefined){
                  this.userLocal.userDetails.fullAddress=this.address.place_name;
                }
                this.$parent.save(this);
            },
            saveDate (date) {
                this.$refs.datepicker.save(date)
            },
            async findGeolocation() {
                if (this.userLocal.userDetails!==undefined){
                    let result = await this.$store.dispatch('getGeolocationAddress', this.userLocal.userDetails);
                    return result;
                    // this.$store.dispatch('getGeolocationAddress', this.userLocal.userDetails)
                    //     .then((response) => {
                    //         return response;
                    //         // if (response !== undefined && response.statusCode === 200) {
                    //         //     console.log(response);
                    //         //
                    //         // }
                    //     });
                }
            },
            getLocation(val){
                if (val!==undefined && val.context!==undefined){
                    let place;
                    val.context.forEach( c => {
                        if (c.id.includes("postcode")){
                            this.userLocal.userDetails.postcode=c.text;
                        }else if (c.id.includes("locality")){
                            this.userLocal.userDetails.city=c.text;
                        }else if (c.id.includes("place")){
                            place=c.text;
                        }else if (c.id.includes("country")){
                            this.userLocal.userDetails.country=c.text;
                        }else if (c.id.includes("region")) {
                          this.userLocal.userDetails.region = c.text;
                          if (c.short_code!==undefined){
                            this.userLocal.company.region= c.short_code.substring(c.short_code.indexOf("-")+1,c.short_code.length);
                          }
                        }
                    });
                    if (this.userLocal.userDetails.city===undefined && place !==undefined){
                        this.userLocal.userDetails.city=place;
                    }
                    this.userLocal.userDetails.address=val.address + " " + val.text;
                }else{
                    this.userLocal.userDetails.postcode=undefined;
                    this.userLocal.userDetails.city=undefined;
                    this.userLocal.userDetails.country=undefined;
                    this.userLocal.userDetails.address=undefined;
                  this.userLocal.userDetails.region=undefined;
                }
                this.userLocal.userDetails.location=val.geometry;
                return val;
            },
            validate () {
                if (this.$refs.form!==undefined){
                    return this.$refs.form.validate();
                }
            },
        },
        computed: {
            ...mapGetters([
                'getUser'
            ]),
            user() {
                if (this.$parent.user!=undefined){
                    this.cancel();
                }
                return this.$parent.user;
            },
        },
        watch: {
            datepicker (val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
            lookupAddress (val) {
                if (val===undefined || val===null || val.length<4) {
                  // if (val===null && this.userLocal.userDetails.fullAddress!==undefined){
                  //   val=this.userLocal.userDetails.fullAddress;
                  //   this.address={};
                  //   this.address.place_name=val;
                  //   this.addressItems=[];
                  //   this.addressItems.push(this.address);
                  //   return
                  // }else{
                    return
                  // }
                }
                // Items have already been loaded
                // if (this.items !==undefined && this.items.length > 0) return

                // Items have already been requested
                if (this.isLoading) return

                this.isLoading = true

                // Lazily load input items
                let that=this;
                // using mapbox
                this.$store.dispatch('lookupAddressMapbox',val).
                then(response => {
                    that.addressItems=response;
                })
                    .finally(() => (this.isLoading = false));
            },
        },
        beforeRouteLeave (to, from, next) {
            console.log("route leave");
            if (this.userLocal){
              let copyLocal=JSON.parse(JSON.stringify(this.userLocal));
              if (Object.keys(copyLocal.userDetails).length === 0){
                delete copyLocal.userDetails;
              }
              let local=JSON.stringify(copyLocal);
              let remote=JSON.stringify(this.$parent.user);
              if (local !== remote){
                this.$parent.dirty=true;
                this.$parent.next=next;
              }else{
                next();
              }
            }

        }
    }
</script>

<style scoped>

</style>