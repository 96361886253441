<template>
    <v-container v-if="user && userLocal">
        <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >
        <v-row class="margin-bottom" align="center">
            <h2>Profile</h2>
            <a v-if=" false &&  nmuser !==undefined && user.profile !==undefined && user.profile.profileName!==undefined" class="margin-default" target="_blank" href="/profile" @click.prevent="openProfile()">Preview your public profile</a>
            <v-spacer></v-spacer>
            <v-btn id="cancel" outlined @click="cancel" class="margin-left-right-default">Cancel</v-btn>
            <v-btn id="btn_save" color="primary" class="margin-right-default" :disabled="!valid"  @click="save()" :loading="loading">Save</v-btn>
        </v-row>
        <v-row no-gutters align="center">
            <v-avatar size="124">
                <img    style="object-fit: cover;"
                        v-if="userLocal.profile.photoPath"
                        :src="userLocal.profile.photoPath+userLocal.profile.photoFilename"
                        :alt="userLocal.profile.profileName"
                />
            </v-avatar>
            <v-file-input
                id="file"
                label="File input"
                          v-model="photo"
                          :show-size=true
                          accept="image/*"
                          :rules="inputFileRule"
            ></v-file-input>
            <v-btn id="btn_upload" outlined small @click="upload" class="margin-left-default" :loading="loadingUpload">Upload</v-btn>
        </v-row>
        <v-text-field
            id="profileName"
                label="Profile name"
                v-model="userLocal.profile.profileName"
                :placeholder="userLocal.userDetails.firstname+' '+userLocal.userDetails.lastname"
                :rules="[v => !!v  || 'This field is required!']"
        >
        </v-text-field>
        <v-textarea
            id="description"
                v-model="userLocal.profile.about"
                label="About you"
                hint="Describe your professional experience, why you cook, your background, your cuisine style"
                placeholder="Describe your professional experience, why you cook, your background, your cuisine style"
                :rules="[v => !!v  || 'This field is required!']"
        >
        </v-textarea>
        <v-select
            id="languages"
                v-model="userLocal.profile.languages"
                :items="this.$store.state.languages"
                item-text="displayName"
                item-value="name"
                attach
                chips
                label="Languages"
                multiple
                color="#00695C  "
                :rules="[v => !!v && v.length !== 0 || 'You must select one language!']"
                required
                return-object
        ></v-select>
            <v-radio-group
                id="induction"
                    v-model="userLocal.profile.induction"
                    row
                    label="Are you equipped to work with Induction Cook Top :"
                    :rules="[v => v!==undefined  || 'This field is required!']"
            >
                <v-radio
                        label="Yes"
                        :value="true"
                ></v-radio>
                <v-radio
                        label="No"
                        :value="false"
                ></v-radio>
            </v-radio-group>
            <div v-if="userLocal.locations">
                <v-text-field
                        label="Maximun distance (Km)"
                        v-model="userLocal.locations[0].maxRange"
                        type="number"
                >
                </v-text-field>
                {{this.userLocal.locations[0].location.coordinates}}
                <div style="height: 500px; width: 100%">
                    <l-map
                            ref="map"
                            v-if="showMap && this.userLocal.locations[0] && this.userLocal.locations[0].location && this.userLocal.locations[0].location.coordinates "
                            :zoom="zoom"
                            :center="[this.userLocal.locations[0].location.coordinates[1],this.userLocal.locations[0].location.coordinates[0]]"
                            :options="mapOptions"
                            style="height: 80%;z-index: 0"
                            @update:center="centerUpdate"
                            @update:zoom="zoomUpdate"
                    >
                        <l-tile-layer
                                :url="url"
                                :attribution="attribution"
                        />

                        <l-circle
                                :lat-lng="[this.userLocal.locations[0].location.coordinates[1],this.userLocal.locations[0].location.coordinates[0]]"
                                :radius="maxRange"
                        />
<!--                        [this.userLocal.locations[0].location.coordinates[1],this.userLocal.locations[0].location.coordinates[0]]-->
                        <l-marker v-if="this.userLocal.locations[0].location.coordinates" :lat-lng="[this.userLocal.locations[0].location.coordinates[1],this.userLocal.locations[0].location.coordinates[0]]" :draggable="true" name="hg" @dragend="move">
        <!--                    <l-popup>-->
        <!--                        <div @click="innerClick">-->
        <!--                            I am a popup-->
        <!--                            <p v-show="showParagraph">-->
        <!--                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque-->
        <!--                                sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.-->
        <!--                                Donec finibus semper metus id malesuada.-->
        <!--                            </p>-->
        <!--                        </div>-->
        <!--                    </l-popup>-->
                        </l-marker>

                    </l-map>
                </div>
            </div>
        </v-form>
    </v-container>
</template>

<script>
    import {} from 'vuex';
    import L from 'leaflet';
    import { latLng } from "leaflet";
    import { LMap, LCircle, LTileLayer, LMarker } from "vue2-leaflet";
    import Constants from '../../constants';
    import 'leaflet/dist/leaflet.css';
    import { Icon } from 'leaflet';
    import Vue from "vue";

    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    Vue.component('l-map', LMap);
    Vue.component('l-tile-layer', LTileLayer);
    Vue.component('l-marker', LMarker);


    export default {
        name: "Profile",
        components: {
            LMap,
            LCircle,
        },
        data() {
            return {
                userLocal:undefined,
                languages:undefined,
                about:undefined,
                photo:undefined,
                valid:true,
                inputFileRule: [
                    value => !value || value.size < 10000000 || 'Avatar size should be less than 10 MB!',
                ],
                zoom: 9,
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution:
                    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                withPopup: latLng(47.41322, -1.219482),
                withTooltip: latLng(47.41422, -1.250482),
                currentZoom: 11.5,
                currentCenter: latLng(47.41322, -1.219482),
                showParagraph: false,
                mapOptions: {
                    zoomSnap: 0.5
                },
                showMap: true,
                marker:{ lat: -28.0931614,lng:  153.4358054 },
                test:undefined,
                loading:false,
                loadingUpload:false,
            };
        },
        methods: {
          openProfile(){
            let path = `/profile/` + this.user.profile.profileName;
            window.open(path, '_blank');
          },
          nameValidation(e){
            console.log(e);
            if(e!==undefined && !e.key.match(/^[a-zA-Z]*$/))
            {
              e.preventDefault();
            }

          },
            move(event){
                // console.log(event);
                this.userLocal.locations[0].location.coordinates=[event.target._latlng.lng,event.target._latlng.lat];
            },
            cancel(){
                let copy=JSON.parse(JSON.stringify(this.$parent.user));

                this.$set(copy, 'profile',copy.profile);

                if (copy.profile===undefined){
                    copy.profile={};
                    // copy.profile.profileName=copy.userDetails.firstname+" "+copy.userDetails.lastname;
                }
                // if ((copy.profile.profileName===undefined || copy.profile.profileName==='') && copy.userDetails!==undefined){
                //     copy.profile.profileName=copy.userDetails.firstname+" "+copy.userDetails.lastname;
                // }
                if (copy.userDetails !==undefined && copy.userDetails.location!==undefined ) {
                    this.$set(copy, 'locations',copy.locations);
                    if (copy.locations===undefined){
                        copy.locations=[];
                    }
                    if (copy.locations.length===0){
                        copy.locations[0]={};
                    }
                    if (copy.locations[0].location===undefined && copy.userDetails.location!==undefined ){
                        copy.locations[0].location={ "type": "Point", "coordinates": [ copy.userDetails.location.coordinates[0], copy.userDetails.location.coordinates[1] ] }
                        // copy.locations[0].location=latLng(copy.userDetails.location.coordinates[0], copy.userDetails.location.coordinates[1]);;
                    }
                    if (copy.locations[0].maxRange===undefined){
                        copy.locations[0].maxRange=50;
                    }

                    this.$set(copy.locations[0], 'maxRange',copy.locations[0].maxRange);
                }

                this.userLocal=copy;
                // this.marker={ lng: copy.userDetails.location.coordinates[0], lat: copy.userDetails.location.coordinates[1] },
                // [copy.userDetails.location.coordinates[0],copy.userDetails.location.coordinates[1]];
                console.log(this.withPopup);
            },
            save() {
                if (this.validate()!=true){
                    return
                }
                console.log("save");
              this.$parent.save(this);
            },
            upload() {
                this.loadingUpload=true;
                let data={};
                data.photo=this.photo;
                data.user=this.$parent.user;
                this.$store.dispatch('uploadProfilePhoto', data)
                    .then((response) => {
                        if (response !== undefined && response.statusCode === 200) {
                            this.$store.dispatch('showSnackbar', {
                                text: Constants.SAVED,
                                color: "primary"
                            });
                            this.photo=undefined;
                            this.$parent.user.profile.photoPath=response.result.photoPath;
                            this.$parent.user.profile.photoFilename=response.result.photoFilename;
                            this.userLocal.profile.photoPath=response.result.photoPath;
                            this.userLocal.profile.photoFilename=response.result.photoFilename;
                        }
                    }).finally(()=>{this.loadingUpload=false;});
            },
            validate () {
                if (this.$refs.form!==undefined){
                    return this.$refs.form.validate();
                }
            },
            zoomUpdate(zoom) {
                this.currentZoom = zoom;
            },
            centerUpdate(center) {
                this.currentCenter = center;
            },
            showLongText() {
                this.showParagraph = !this.showParagraph;
            },
            innerClick() {
                alert("Click!");
            },

        },
        computed: {
            user() {
                if (this.$parent.user!=undefined){
                    this.cancel();
                }
                return this.$parent.user;
            },
            maxRange() {
                let maxRange
                if (this.userLocal.locations!==undefined && this.userLocal.locations[0]!==undefined && this.userLocal.locations[0].maxRange!==undefined){
                    maxRange=parseInt(this.userLocal.locations[0].maxRange)*1000;
                    console.log(maxRange);
                }
                return maxRange;
            },
            },
            mounted() {
                if (this.$refs.map!==undefined){
                    L.control.scale().addTo(this.$refs.map.mapObject);
                }
                // this.$refs.map.mapObject.setView([70, 120], 1);
            },
            beforeRouteLeave (to, from, next) {
                console.log("route leave");
                let copyLocal=JSON.parse(JSON.stringify(this.userLocal));
                if (Object.keys(copyLocal.profile).length === 0){
                    delete copyLocal.profile;
                }
                let local=JSON.stringify(copyLocal);
                let remote=JSON.stringify(this.$parent.user);
                if (local !== remote){
                    this.$parent.dirty=true;
                    this.$parent.next=next;
                }else{
                    next();
                }
            }
    }
</script>

<style scoped>

</style>