<template>
    <!-- EXAMPLE-->
    <v-card class="margin-card-default" v-if="service && generateExample" elevation="4">
      <v-card-text>
        <v-row align="center" no-gutters  >
            <div style="" class="primary--text margin-right-default" @click="expandExample=!expandExample">Example order</div>
            <v-select
                    v-model="dishTypesSelected"
                    :items="dishTypesAvailable"
                    item-text="name"
                    attach
                    chips
                    label="Categories to include"
                    multiple
                    color="#00695C  "
                    :rules="[v => !!v && v.length !== 0 || 'You must select one Category!']"
                    required
                    :return-object=true
            ></v-select>
            <v-spacer @click="expandExample=!expandExample"></v-spacer>
            <v-btn icon color="primary" @click="expandExample=!expandExample">
                <v-icon>{{expandExample?'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
            </v-btn>
        </v-row>
        <div v-if="expandExample">
            <v-data-table v-if="generateExample"
                          :headers="generateExample.headers"
                          :items="generateExample.orders"
                          :disable-sort=true
                          class="elevation-1"
                          :hide-default-footer=true
                          :expanded.sync="generateExample.expanded"
                          item-key="title"
                          show-expand
            >
                <template v-slot:expanded-item="{ headers, item }" >
<!--                    v-data-table__mobile-row-->
                    <td :colspan="headers.length" style="white-space: pre-wrap" class="  caption">{{ item.details }}<br/>{{item.details2}}</td>
<!--                    <td :colspan="headers.length-1" style="white-space: pre-wrap" class="caption">{{item.details2}}</td>-->
                </template>
            </v-data-table>
            <div v-if="generateExample.underMinPrice" class="caption">(*) Your minimum service price</div>
            <div v-if="generateExample.waiter" class="caption">(**) Includes the waiter price</div>
        </div>
      </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "MenuExample",
        props: {
            service: Object,
            closeAction: Function,
        },
        data() {
            return {
                expandExample:true,
                example:undefined,
                dishTypesSelected:[],
            };
        },
        methods: {
            initDishTypesSelected(){
                let list=[];

              if (this.service!==undefined && this.service.dishes!==undefined ){
                for (let i = 0; i < this.service.itemCategories.length; i++) {
                  let category = this.service.itemCategories[i];
                  if (this.service.dishes.filter(d => d.itemCategory.name === category.name).length > 0) {
                    list.push(category);
                  }
                }
                this.dishTypesSelected=list;
              }

                // if (this.$store.state.dishType!==undefined){
                //     for (let i = 0; i < this.$store.state.dishType.length; i++) {
                //         let dishType = this.$store.state.dishType[i];
                //         if (this.service!==undefined && this.service.dishes!==undefined && this.service.dishes.filter(d => d.itemCategory.categoryType.name === dishType.name).length > 0) {
                //             if (list.filter(d => d.name === dishType.name).length === 0) {
                //                 list.push(dishType);
                //             }
                //         }
                //     }
                //     this.dishTypesSelected=list;
                // }
            },
            findFromPriceDishSelected(service,dishTypesSelected) {
                if (dishTypesSelected === undefined){
                    return;
                }
                let from={};
                from.listCheapDishType=[];
                let fromPriceDishSelected=0;
                let fromRetailDishSelected=0;
                for (let i=0; i < dishTypesSelected.length; i++){
                    let cheapestDishType = service.dishes.filter(d => d.itemCategory.name === dishTypesSelected[i].name).slice().sort(function (a, b) {
                        return a.price - b.price;
                    });
                    if (cheapestDishType[0]!=undefined){
                        fromPriceDishSelected+=cheapestDishType[0].price;
                        fromRetailDishSelected+=this.getPriceWithCommission(cheapestDishType[0].price)
                            // Math.round((parseFloat(cheapestDishType[0].price) * (1+this.$store.state.commission/100) )*10/5)*5/10;
                        let cheapDishType={};
                        cheapDishType.dishType=dishTypesSelected[i];
                        cheapDishType.price=cheapestDishType[0].price;
                        cheapDishType.retail=this.getPriceWithCommission(cheapestDishType[0].price)
                        cheapDishType.itemCategory = dishTypesSelected[i];

                        // Math.round((parseFloat(cheapestDishType[0].price) * (1+this.$store.state.commission/100) )*10/5)*5/10;
                        from.listCheapDishType.push(cheapDishType);
                    }
                }
                from.price=fromPriceDishSelected;
                from.retail=fromRetailDishSelected;
                return from;
            },
        },
        computed: {
            generateExample() {
                let service = this.service;
                let example=undefined;
                if (service !== undefined && service.dishes!=undefined && service.dishes.length > 0 && this.$store.state.dishType !== undefined) {
                    if (example === undefined) {
                        example = {};
                        // this.$set(this.example, 'dishTypesSelected', []);
                        // this.$set(this.example, 'dishTypesAvailable', []);
                        // this.$set(this.example, 'orders', []);

                        // this.$set(service, 'example', {});
                        // this.$set(service.example, 'dishTypesSelected', []);
                        // this.$set(service.example, 'dishTypesAvailable', []);

                        // service.example={};
                        // service.dishTypesSelected=[];
                        // service.example.dishTypesAvailable=[];

                        let headers = [
                            {
                                text: 'People',
                                align: 'start',
                                sortable: false,
                                value: 'title',
                            },
                            // { text: 'Min $', value: 'min', sortable: false },
                            {text: 'Chef Earnings $', value: 'chef', sortable: false},
                            {text: 'Retail $', value: 'retail', sortable: false},
                            {text: '', value: 'data-table-expand'},
                        ]
                        example.headers = headers;
                        example.expanded = [];
                        example.underMinPrice = false;
                        example.waiter = false
                    }

                    // let from=this.findFromPrice(service);

                    let from = this.findFromPriceDishSelected(service, this.dishTypesSelected);
                    let orders = [];
                    let numberDishes = this.dishTypesSelected !== undefined ? this.dishTypesSelected.length : 0;

                    for (let i = 1; i <= 5; i++) {
                        let order = {};
                        let people = parseInt(i * 2);
                        order.title = people;
                        order.min = from !== undefined ? from.price * people : 0;
                        order.minRretail = from !== undefined ? from.retail * people : 0;
                        let chefTotal=0;
                        let retailTotal=0 ;
                        let minPriceReached = false;
                        let waiter = false;
                        if (service.minWaiterDishes > 0 && numberDishes * people >= service.minWaiterDishes && service.waiterPrice > 0) {
                          chefTotal += parseFloat(service.waiterPrice);
                          retailTotal+=this.getPriceWithCommission(service.waiterPrice);
                          // Math.round((parseInt(service.waiterPrice) * (1+this.$store.state.commission/100) )*10/5)*5/10;
                          waiter = true;
                          example.waiter = true;
                        }

                      chefTotal += from !== undefined ? (this.roundToXDigits(parseInt(from.price) * parseInt(people),2)) : 0;
                      retailTotal += from !== undefined ? (this.roundToXDigits(parseFloat(from.retail) * parseInt(people) ,2)) : 0;

                        if (from !== undefined && from.price !== undefined && service.minPrice !== undefined && service.minPrice > chefTotal) {
                            chefTotal = parseInt(service.minPrice);
                            minPriceReached = true;
                            example.underMinPrice = true;
                            retailTotal=this.getPriceWithCommission(service.minPrice);

                                // Math.round((parseInt(service.minPrice) * (1+this.$store.state.commission/100) )*10/5)*5/10;
                        }

                        order.retail = this.roundToXDigits(retailTotal,2).toLocaleString(undefined,{ minimumFractionDigits: 2 }) ;
                        chefTotal=this.roundToXDigits(chefTotal,2).toLocaleString(undefined,{ minimumFractionDigits: 2 });

                        if (minPriceReached) {
                            chefTotal += " (*)"
                        }
                        if (waiter) {
                            chefTotal += " (**)"
                        }
                        order.chef = chefTotal;

                        let details = "";

                        let total = order.min;
                        if (from !== undefined) {
                            for (let i = 0; i < from.listCheapDishType.length; i++) {
                                from.listCheapDishType[i].retailPrice=this.getPriceWithCommission(from.listCheapDishType[i].price);
                                    // Math.round((from.listCheapDishType[i].price * (1+this.$store.state.commission/100) )*10/5)*5/10;
                                details += people + " X " + from.listCheapDishType[i].itemCategory.name + " ($" + from.listCheapDishType[i].price + ") = $" + people * from.listCheapDishType[i].price + "\n";
                            }
                        }

                        if (waiter) {
                            total += parseFloat(service.waiterPrice);
                            details += "Waiter = $" + service.waiterPrice + "\n";
                        }

                        total=this.roundToXDigits(total,2).toLocaleString(undefined,{ minimumFractionDigits: 2 });

                        details += "Total = $" + total;

                        order.details = details;
                        let details2 = "";

                        if (waiter) {
                            details2 += "The number of dishes (" + numberDishes * people + ") reached your minimum dishes (" + service.minWaiterDishes + ") to order a waiter ($" + service.waiterPrice + ").\n"
                        }
                        if (minPriceReached) {
                          details2 += "The order is under your minimum earnings ($" + service.minPrice + ") , you will receive $" + service.minPrice + " for your service.\n"
                        }
                        order.details2 = details2;

                        orders.push(order);
                    }
                    example.orders = orders;
                }
                return example;
            },
            dishTypesAvailable(){
              let list=[];
              for (let i = 0; i < this.service.itemCategories.length; i++) {
                let category = this.service.itemCategories[i];
                if (this.service.dishes.filter(d => d.itemCategory.name === category.name).length > 0) {
                  list.push(category);
                }
              }
              this.initDishTypesSelected()

              return list;

                // if (this.$store.state.dishType!==undefined){
                //     for (let i = 0; i < this.$store.state.dishType.length; i++) {
                //         let dishType = this.$store.state.dishType[i];
                //         if (this.service.dishes.filter(d => d.itemCategory.categoryType.name === dishType.name).length > 0) {
                //             list.push(dishType);
                //             //     if (this.dishTypesSelected.filter(d => d.name === dishType.name).length === 0) {
                //             //         this.dishTypesSelected.push(dishType);
                //             //     }
                //         }
                //     }
                //     this.initDishTypesSelected()
                // }
                // return list;
            }
        }
    }
</script>

<style scoped>

</style>