<template>
    <v-dialog v-if="localPackage" v-model="packageDialog" persistent max-width="1200px">
        <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="validate()!=true?null:edit()">
            <v-card>
                <v-card-title>
                    <div class="headline">Package</div>
                </v-card-title>
                <v-card-text>
                    <v-container>

                        <v-row align="center" >
                          <v-col cols="12" md="6" >
                            <v-text-field
                                id="package_description"
                                label="Package description"
                                v-model="localPackage.name"
                                type="text"
                                :rules="[v => !!v && v.length !== 0 || 'You must enter a description !']"
                                required
                            >
                            </v-text-field>

                          </v-col>
                            <v-col cols="12" md="6" >
                              <v-text-field
                                  id="package_price"
                                  ref="price"
                                  label="Price per person"
                                  v-model.number="localPackage.price"
                                  type="number"
                                  :rules="[v => !!v && v.length !== 0 || 'You must enter a price !']"
                                  hint="The price per person must include everything (Taxes, buying/preparation/driving/cooking/serving/cleaning time )"
                                  :persistent-hint="true"
                              ></v-text-field>
                            </v-col>
                         
                        </v-row>
                      <v-row align="start" >
                        <v-col cols="12" md="6" >
                          <v-text-field
                              id="min_guests"
                              label="Mininum guests"
                              type="number"
                              min="0"
                              max="999"
                              v-model.number="localPackage.minGuests"
                              hint="Minimum number of guests required for this package"
                              :persistent-hint="true"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" >
                          <v-text-field
                              id="max_guests"
                              label="Maximum guests"
                              type="number"
                              min="0"
                              max="999"
                              v-model.number="localPackage.maxGuests"
                              hint="Maximum number of guests for this package"
                              :persistent-hint="true"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-form
                          ref="formCategory"
                          v-model="validCategory"
                          lazy-validation
                          @submit.prevent="validateCategory()!=true?null:addPackageCategory()">
                      <v-card elevation="4" v-if="localPackage" class="margin-top-default">
                        <v-card-title>Package composition</v-card-title>
                        <v-card-text>
                        <div v-for="(category) in packageCategoriesSorted(localPackage)" :key="category.name" class="margin-top-default">
                          {{category.itemCategory.name}} - {{category.selectionType.displayName}} <v-icon small @click.capture="removeCategory(category)">mdi-delete-outline</v-icon>
                          <ul v-for="(item,index) in filteredDishesByCategoryForPackages(service,category)" v-bind:key="index" class="margin-top-default">
<!--                          <ul v-for="(item) in category.limitedItems" :key="item.description" class="margin-top-default">-->
                            <li>{{item.description}}</li>
                          </ul>
                        </div>
                        </v-card-text>
                      </v-card>

                      <v-card elevation="4" class="margin-top-default">
                        <v-card-title>Add a category to the package</v-card-title>
                        <v-card-text>
                          <v-row align="start" >
                              <v-col cols="12" md="4" v>
                              <v-select
                                  id="category"
                                  v-model="newPackageCategory"
                                  :items="categoriesNotUsed()"
                                  item-text="name"
                                  :return-object=true
                                  label="Category"
                                  :rules="[v => !!v && v.length !== 0 || 'You must select one Category !']"
                                  required
                              ></v-select>
                              </v-col>
                              <v-col cols="12" md="4" >
                                <v-select
                                    id="category"
                                    v-model="newPackageSelectionType"
                                    :items="selectionTypes"
                                    item-text="displayName"
                                    :return-object=true
                                    label="Selection types"
                                    :rules="[v => !!v && v.length !== 0 || 'You must select one selection type !']"
                                    required
                                ></v-select>
                              </v-col>
                              <v-col cols="12" md="4" >
                                <v-select
                                    id="restricted_items"
                                    v-model="newPackageLimitedItems"
                                    :items="itemsCategory"
                                    multiple
                                    item-text="description"
                                    :return-object=true
                                    label="Limited list of items"
                                    hint="Leave blank to include all the items in the category"
                                    :persistent-hint="true"
                                ></v-select>
                              </v-col>
                          </v-row>
                          <v-row align="start" v-if="newPackageSelectionType !== undefined && newPackageSelectionType.name=='MULTIPLE'">
                          <v-col cols="12" md="4" >
                            <v-text-field
                                id="minItem"
                                label="Mininum number of items to select"
                                type="number"
                                min="0"
                                max="999"
                                v-model.number="minItem"

                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4" >
                            <v-text-field
                                id="maxItem"
                                label="Maximum number of items to select"
                                type="number"
                                min="0"
                                max="999"
                                v-model.number="maxItem"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn id="btn_add_category" outlined small color="primary" type="submit" :disabled="!validCategory" >Add </v-btn>
                        </v-card-actions>
                      </v-card>
                      </v-form>

                    </v-container>


                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="btn_package_close" outlined @click="closeAction()">Close</v-btn>
                    <v-btn id="btn_package_action" color="primary" type="submit" :disabled="!valid">{{ localPackage.edit === true ? "Edit" : "Add" }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    export default {
        name: "PopupPackage",
        props: {
            service:Object,
            packageDialog:Boolean,
            newPackage: Object,
            closeAction:Function,
            editAction:Function,
        },
        data() {
            return {
                localPackage:{},
                expandExample:true,
                example:undefined,
                newPackageCategory:undefined,
                newPackageSelectionType:undefined,
                newPackageLimitedItems:undefined,
                valid:true,
                validCategory:true,
            };
        },
        created(){
          let newPackage=this.newPackage;
          return this.updateLocal(newPackage);
        },
        methods:{
            updateLocal(){
                let copy;
                let service=this.service;
                if ( this.newPackage!==undefined ) {
                    copy = JSON.parse(JSON.stringify(this.newPackage));
                    // this.newPackage=JSON.parse(JSON.stringify(editItem));;
                    copy.edit = true;
                    copy.index = service.packages.indexOf(this.newPackage);
                }else{
                    copy={};
                    copy.name=undefined;
                    copy.price=undefined;
                    copy.packageCategories=[];
                    copy.minGuests=undefined;
                    copy.maxGuests=undefined;
                }
                // this.newPackage=copy;
                this.localPackage=copy;
                return this.localPackage;
            },
            validate () {
                if (this.$refs.form!==undefined && this.$refs.form!==undefined){
                    return this.$refs.form.validate();
                }
                return true;
            },
            validateCategory () {
              if (this.$refs.formCategory!==undefined && this.$refs.formCategory!==undefined){
                return this.$refs.formCategory.validate();
              }
              return true;
            },
          edit(){
            console.log("edit call");
            this.editAction(this.localPackage, this.service );
          },
          addPackageCategory(){
            let packageCategory = {};
            packageCategory.selectionType=this.newPackageSelectionType;
            packageCategory.itemCategory=this.newPackageCategory;
            packageCategory.limitedItems=this.newPackageLimitedItems;
            packageCategory.minItem=this.minItem;
            packageCategory.maxItem=this.maxItem;
            this.localPackage.packageCategories.push(packageCategory);
            this.newPackageLimitedItems=undefined;
            this.newPackageSelectionType=undefined;
            this.newPackageCategory=undefined;
            this.minItem=undefined;
            this.maxItem=undefined;
            this.validCategory=undefined;
            console.log("added");
          },
          removeCategory(category){
            this.localPackage.packageCategories.splice(this.localPackage.packageCategories.findIndex(c => c.itemCategory.name === category.itemCategory.name), 1);
          },
          items(){
            return this.newPackageCategory.items();
          },
          categoriesNotUsed(){
            let excludedCategories =[];
            if (this.localPackage.packageCategories != undefined){
              excludedCategories = excludedCategories.concat(this.localPackage.packageCategories.map(cat => cat.itemCategory.name));
            }
            let filteredCategories = this.service.itemCategories.filter(dt => !excludedCategories.includes(dt.name));
            return filteredCategories;
          },
        },
        computed:{
          categories(){
            return this.service.itemCategories;
          },
          selectionTypes(){
            return this.$store.state.selectionType;
          },

          // checkPackage(){
          //
          // },
          itemsCategory(){
            let category=this.newPackageCategory;
            let items = this.service.dishes;
            if (this.newPackageCategory!==undefined){
                  return items.filter(d => d.itemCategory!==undefined && d.itemCategory.name === category.name)
            }else{
              return undefined;
            }
          }
        },
    }
</script>

<style scoped>

</style>